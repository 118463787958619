import React, { useRef, useState, Suspense, useEffect } from 'react';
import { Canvas, useThree } from '@react-three/fiber';

import Marker, { MAX_MARKER_SIZE, MIN_MARKER_SIZE } from '../Marker';
import CameraControls from '../CameraControls';
import Controls from '../Controls';
import DandyMesh from '../DandyMesh';
import useKeyboardShortcuts from '../useKeyboardShortcuts';
import { Loading } from './Loading';
import CameraRig from './CameraRig';
import HelpKey from '../HelpKey';

import options from '../config.json'

function Raycaster(props) {
  const { raycaster } = useThree();

  useEffect(() => {
    raycaster.near = props.near;
  }, [props.near, raycaster]);

  return null;
}

function VertexPainter() {
  const [loadedAnnotationData, setLoadedAnnotationData] = useState({});
  const [annotationData, setAnnotationData] = useState({});
  const [geometry, setGeometry] = useState(null);
  const [faceIndex, setFaceIndex] = useState(null);
  const [cameraOptions, setCameraOptions] = useState({
    fov: 75,
    position: [-40, 30, 0],
    near: 1,
  });
  const [appearanceOptions, setAppearanceOptions] = useState({
    saturation: 0.0,
    ambientLightIntensity: 0.1,
    pointLightIntensity: 0.25,
    directionalLightIntensity: 0.3,
  });

  const mesh = useRef();
  const camera = useRef();
  const light = useRef();

  const [visibleLabels, setVisibleLabels] = useState(options.labels);
  const [label, setLabel] = useState(null);
  const [marker, setMarker] = useState(null);
  const [isSelecting, setIsSelecting] = useState(null);
  const [hoverLabel, setHoverLabel] = useState(null);
  const [markerSize, setMarkerSize] = useState(4);

  const [mouseHover, setMouseHover] = useState(false);
  const meshPointerEvents = {
    onPointerOver: (event) => {
      setMouseHover(true);
    },
    onPointerOut: (event) => {
      setMouseHover(false);
    },
  };

  const [isFilterFocused, setIsFilterFocused] = useState(false);
  const [hideAllLabels, setHideAllLabels] = useState(false);
  useKeyboardShortcuts(['Digit0'], () => {setHideAllLabels(!hideAllLabels)});

  useKeyboardShortcuts(['Equal', 'NumpadAdd'], (code) => {
    if (markerSize === MAX_MARKER_SIZE) return;
    setMarkerSize(markerSize + 1);
  });

  useKeyboardShortcuts(['Minus', 'NumpadSubtract'], (code) => {
    if (markerSize === MIN_MARKER_SIZE) return;
    setMarkerSize(markerSize - 1);
  });

  function handleCameraChange() {
    if (camera.current && light.current) {
      const {x, y, z} = camera.current.position;
      light.current.position.set(x, y, z);
    }
  }

  useEffect(() => {
    handleCameraChange();
  });

  const activeMarkerHover = mouseHover && (marker != null || isSelecting);

  return (
    <>
      <Controls
        mesh={mesh}
        geometry={geometry}
        setGeometry={setGeometry}
        setFaceIndex={setFaceIndex}

        setMarker={setMarker}

        {...{
          label, setLabel,
          visibleLabels, setVisibleLabels,
          hideAllLabels, setHideAllLabels,
          markerSize, setMarkerSize,
          cameraOptions, setCameraOptions,
          appearanceOptions, setAppearanceOptions,
          isFilterFocused, setIsFilterFocused,
          loadedAnnotationData, setLoadedAnnotationData,
          annotationData, setAnnotationData,
        }}
      />
      <HelpKey showModifier={isFilterFocused}/>

      <Canvas>
        <CameraControls onChange={handleCameraChange} noRotate={label != null || isSelecting} rotateSpeed={4.} />
        <CameraRig cameraRef={camera} {...cameraOptions} />
        <Raycaster near={cameraOptions.near} />
        <ambientLight intensity={appearanceOptions.ambientLightIntensity} />
        <pointLight position={[0, 10, 0]} intensity={appearanceOptions.pointLightIntensity} />
        <pointLight position={[0, -30, 0]} intensity={appearanceOptions.pointLightIntensity} />
        <directionalLight
          ref={light}
          intensity={appearanceOptions.directionalLightIntensity} />

        <Suspense fallback={<Loading />}>
          <DandyMesh
            geometry={geometry}
            meshRef={mesh}
            {...meshPointerEvents}
          >
            <Marker
              marker={marker}
              visibleLabels={visibleLabels}
              visible={activeMarkerHover}
              saturation={appearanceOptions.saturation}
              hideAllLabels={hideAllLabels}
              setLabel={setLabel}
              erase={false}
              faceIndex={faceIndex}
              size={markerSize}
              alpha={false}
              isSelecting={isSelecting}
              setIsSelecting={setIsSelecting}
              hoverLabel={hoverLabel}
              setHoverLabel={setHoverLabel}
            ></Marker>
          </DandyMesh>
        </Suspense>
      </Canvas>
    </>
  );
}

export default VertexPainter;
