import React, { useState } from 'react';

import styles from './styles.module.css';

import options from '../config.json';

function LabelMetadata({
  userInfo,
  setUserInfo,
  annotationData,
  setAnnotationData,
}) {
  // filter labels to those in the specific jaw
  const jaw = annotationData?.jaw;

  const renderControl = (metaAttribute) => {
    if (metaAttribute.type === "checkbox" ) {
      const metaAttributes = annotationData?.metaAttributes || {};
      const checked = !!metaAttributes[metaAttribute.id];
      return <input
        type="checkbox"
        checked={checked}
        label={metaAttribute.id}
        onChange={() => {
          setAnnotationData({
            ...annotationData,
            metaAttributes: {
              ...metaAttributes,
              [metaAttribute.id]: !checked,
            },
          });
        }}
      />
    }
    return null;
  };

  return (
    <div className={styles.metadata}>
      <div>
        <span>
          {userInfo.userEmail ? (
            <button
              onClick={() => {
                window.location.assign("/auth/logout");
              }}
            >Logout</button>
          ) : (
            <button
            onClick={() => {
              window.location.assign("/auth/login");
            }}
          >Login</button>
          )}
        </span>
      </div>
      <div>
        <span>User:</span><span>{userInfo.userEmail || ''}</span>
      </div>
      <div>
        <span>Jaw:</span><span>{jaw}</span>
      </div>
      {
        options.metaAttributes.map((metaAttribute, idx) => {
          return <div key={idx}>
            <span>{metaAttribute.label}:</span><span>{renderControl(metaAttribute)}</span>
          </div>
        })
      }
    </div>
  );
}

export default LabelMetadata;
